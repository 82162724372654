<template>
  <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
    leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
    <div @keydown.esc="isOpen = false" v-show="showBackdrop" class="fixed inset-0 transition-opacity">
      <div @click="isOpen = false" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
    </div>
  </transition>

  <div v-if="showNewClassModal" class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    ">
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          ">
          Nova aula
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input type="date" required :disabled="creatingNewClass" id="new-class-date" class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  " name="name" v-model="newClassDate" placeholder="Data" />
                <!-- @blur="validateInput('newClassDate')" -->
                <!-- <small
                  v-if="errors.newClassDate"
                  id="newClassDateError"
                  class="text-red-500"
                >
                  {{ errors.newClassDate }}
                </small> -->
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button @click="createNewClass" type="button" class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            ">
            <svg v-if="creatingNewClass" class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            <span v-if="!creatingNewClass">Criar</span>
          </button>
          <button type="button" @click="onCloseNewClassModal" class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            ">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showClassContentModal" class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    ">
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          ">
          Conteúdo da aula de {{ formatDate(classBeingEdited?.data) }}
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <textarea class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  " name="conteudo" cols="40" rows="5" v-model="classBeingEdited.conteudo"></textarea>
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button type="button" @click="updateClassContent" class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            ">
            <svg v-if="updatingClassContent" class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            <span v-if="!updatingClassContent">Salvar</span>
          </button>
          <button type="button" @click="onCloseClassContentModal" class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            ">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showPrintModal" class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    ">
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          ">
          Imprimir lista de chamada<br />
          <span class="text-md font-medium text-gray-900">{{
          group.turma + " - " + group.ano
          }}</span>
        </div>
        <div class="p-2 mt-2">
          <div class="flex flex-col mb-2">
            <div class="relative text-left">
              <select v-model="month" name="months" id="months" class="
                  rounded-lg
                  border-transparent
                  flex-1
                  appearance-none
                  border border-gray-300
                  w-full
                  py-2
                  px-4
                  bg-white
                  text-gray-700
                  placeholder-gray-400
                  shadow-sm
                  text-base
                  focus:outline-none
                  focus:ring-2
                  focus:ring-purple-600
                  focus:border-transparent
                ">
                <option v-for="(month, index) in monthsArray" :key="index" :value="month">
                  {{ month.monthLong }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button type="button" @click="generatePrintableRollCall" class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            ">
            <svg v-if="printingRollCall" class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            <span v-if="!printingRollCall">Imprimir</span>
          </button>
          <button type="button" @click="onClosePrintModal" class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            ">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showClassRollCallModal" class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-3/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    ">
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          ">
          Presença da aula de {{ formatDate(classBeingEdited?.data) }}
        </div>
        <div class="p-2 mt-2 overflow-auto">
          <table v-if="classBeingEdited.presencas?.length > 0" class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  ">
                  Aluno
                </th>
                <th scope="col" class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  ">
                  Presença
                </th>
                <th scope="col" class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  ">
                  Observação
                </th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(presenca, index) in classBeingEdited.presencas" :key="index">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-md font-medium text-gray-900">
                      {{ getStudentName(presenca.aluno) }}
                    </div>
                  </div>
                </td>
                ente
                presentepresenontext.res = {};
                <td class="
                    px-4
                    py-4
                    whitespace-nowrap
                    text-center text-sm
                    font-medium
                  ">
                  <input type="checkbox" v-model="presenca.presente" />
                </td>
                <td>
                  <input type="text" v-model="presenca.observacao" />
                </td>
              </tr>
            </tbody>
          </table>

          <h3 class="m-8" v-if="classBeingEdited.presencas.length === 0">
            Não existem alunos cadastrados para esta turma.
          </h3>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button type="button" @click="updateClassRollCall" class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            ">
            <svg v-if="updatingClassRollCall" class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            <span v-if="!updatingClassRollCall">Salvar</span>
          </button>
          <button type="button" @click="onCloseClassRollCallModal" class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            ">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    ">
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2">
        <a @click="navigate('minhas-turmas')" class="cursor-pointer text-indigo-600 hover:text-indigo-900">Voltar para
          Minhas turmas</a>
      </div>

      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">
          Lista de chamada
          <br />
          <span v-if="loading" class="text-xl loading">Carregando</span>
          <span class="text-xl" v-if="group && !loading">{{
          `${group?.turma} - ${group?.ano}`
          }}</span>
        </h1>
        <div class="flex justify-between">
          <button @click="onShowNewClassModal" class="
              flex
              h-9
              cursor-pointer
              px-3
              py-2
              bg-gray-800
              ml-2
              text-white text-xs
              font-bold
              uppercase
              rounded
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              transition
              ease-in
              duration-200
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
            ">
            Nova aula
          </button>
          <button @click="onShowPrintModal" class="
              flex
              h-9
              cursor-pointer
              px-3
              py-2
              bg-gray-800
              ml-2
              text-white text-xs
              font-bold
              uppercase
              rounded
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              transition
              ease-in
              duration-200
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
            ">
            Imprimir
          </button>
        </div>
      </div>

      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h3 class="text-center w-full m-12" v-if="!loading && groupClasses.length === 0">
          Não existem aulas criadas para esta turma
        </h3>

        <svg v-if="loading" class="
            relative
            left-1/2
            animate-spin
            -ml-1
            m-14
            h-10
            w-10
            text-indigo-500
          " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>

        <table v-if="!loading && groupClasses.length !== 0" class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                ">
                Data
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody v-if="loading">
            <tr>
              <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                <svg class="
                    relative
                    left-1/2
                    animate-spin
                    -ml-1
                    mr-3
                    h-5
                    w-5
                    text-purple-600
                  " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loading && groupClasses.length !== 0" class="bg-white divide-y divide-gray-200">
            <tr v-for="groupClass in groupClasses" :key="groupClass.id">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="text-md font-medium text-gray-900">
                    {{ formatDate(groupClass.data) }}<br />
                  </div>
                </div>
              </td>
              <td class="
                  px-4
                  py-4
                  whitespace-nowrap
                  text-right text-sm
                  font-medium
                ">
                <a class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                  @click="onShowClassContentModal(groupClass)">Conteúdo</a>
              </td>
              <td class="
                  px-4
                  py-4
                  whitespace-nowrap
                  text-right text-sm
                  font-medium
                " @click="onShowClassRollCallModal(groupClass)">
                <a class="cursor-pointer text-indigo-600 hover:text-indigo-900">Frequência</a>
              </td>
              <td class="
                  px-4
                  py-4
                  whitespace-nowrap
                  text-right text-sm
                  font-medium
                " @click="onDeleteGroupClass(groupClass)">
                <a class="cursor-pointer text-indigo-600 hover:text-indigo-900">Excluir</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Firebase from "@/firebase";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
import { DateTime, Duration } from "luxon";
import { getCurrentInstance } from "vue";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../helpers/pdf_logo";

export default {
  components: {},
  setup() {
    const app = getCurrentInstance();
    const toast = app.appContext.provides.$toast;
    const route = useRoute();
    const group = ref(null);
    const teacher = ref(null);
    const loading = ref(true);
    const groupClasses = ref([]);
    const groupStudents = ref([]);
    const month = ref(null);
    const monthsArray = ref([]);

    const groupId = route.params.groupId;

    const getGroupAndStudentsAndClasses = async () => {
      loading.value = true;

      try {
        const groupSnapshot = await Firebase.getDoc(
          `${Firebase.collections.GROUPS}/${groupId}`
        );
        group.value = { ...groupSnapshot.data(), id: groupSnapshot.id };
        const groupClassesSnapshots = await Firebase.getDocs(
          Firebase.collections.GROUP_CLASSES(groupId),
          Firebase.orderBy("data")
        );

        const teacherSnapshot = await Firebase.getDoc(
          Firebase.collections.TEACHERS + "/" + group.value.professor.id
        );
        teacher.value = { ...teacherSnapshot.data(), id: teacherSnapshot.id };

        const promises = [];

        group.value.alunos?.forEach((aluno) => {
          promises.push(
            Firebase.getDoc(Firebase.collections.STUDENTS + "/" + aluno)
          );
        });

        const studentsSnapshots = await Promise.all(promises);

        groupStudents.value = studentsSnapshots.map((snapshot) => ({
          id: snapshot.id,
          ...snapshot.data(),
        }));

        groupClasses.value = groupClassesSnapshots.docs.map((snapshot) => {
          const _data = { ...snapshot.data(), id: snapshot.id };
          _data.data = DateTime.fromSeconds(_data.data.seconds);

          if (!_data.presencas || _data.presencas.length === 0) {
            _data.presencas = groupStudents.value
              .map((s) => {
                return {
                  aluno: Firebase.doc(
                    Firebase.collections.GROUP_STUDENTS(groupId) + "/" + s.id
                  ),
                  presente: true,
                  observacao: "",
                };
              })
              .sort((a, b) => {
                const aName = groupStudents.value.find(
                  (s) => s.id === a.aluno.id
                )?.nome;
                const bName = groupStudents.value.find(
                  (s) => s.id === b.aluno.id
                )?.nome;
                return (aName ?? "").localeCompare(bName ?? "");
              });
          }
          return _data;
        });

        const _months = [];
        groupClasses.value.forEach((g) => {
          const monthLong = g.data.setLocale("pt-BR").monthLong;
          const month = g.data.setLocale("pt-BR").month;

          if (!_months.some((m) => m.month === month)) {
            _months.push({ month, monthLong });
          }
        });
        monthsArray.value = _months;
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível carregar as suas aulas!",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        loading.value = false;
      }
    };

    const deleteClass = async (id) => {
      await Firebase.deleteDoc(
        Firebase.collections.GROUP_CLASSES(group.value.id) + "/" + id
      );
      groupClasses.value = groupClasses.value.filter((g) => g.id !== id);
    };

    const newClassDate = ref(new Date().toISOString().substr(0, 10));
    const creatingNewClass = ref(false);

    const showBackdrop = ref(false);
    const showNewClassModal = ref(false);

    const setShowBackdrop = (newValue) => {
      showBackdrop.value = newValue;
    };

    const setShowNewClassModal = (newValue) => {
      showNewClassModal.value = newValue;
    };

    const createNewClass = async () => {
      const data = DateTime.fromJSDate(new Date(newClassDate.value)).plus(
        Duration.fromObject({ hours: 4 })
      );

      const duplicateIndex = groupClasses.value.findIndex((g) => {
        return (
          g.data.day === data.day &&
          g.data.month === data.month &&
          g.data.year === data.year
        );
      });

      if (duplicateIndex > -1) {
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não é possível criar duas aulas para o mesmo dia.",
          footer: "Caso o erro persista, contate o administrador.",
        });
        return;
      }

      creatingNewClass.value = true;
      const newClass = { data: data.toJSDate(), conteudo: "", presencas: [] };
      try {
        await Firebase.addDoc(
          Firebase.collections.GROUP_CLASSES(groupId),
          newClass
        );

        toast.open({
          message: "Aula criada com sucesso!",
          type: "success",
          duration: 3000,
          position: "bottom",
          dismissible: true,
        });

        getGroupAndStudentsAndClasses();

        showNewClassModal.value = false;
        showBackdrop.value = false;
      } catch (e) {
        console.error(e);
      } finally {
        creatingNewClass.value = false;
      }
    };

    const showClassContentModal = ref(false);
    const classBeingEdited = ref(null);

    const setClassBeingEdited = (groupClass) => {
      console.log({ groupClassBeingEdited: groupClass })
      classBeingEdited.value = groupClass;
    };

    const setShowClassContentModal = (newValue) => {
      showClassContentModal.value = newValue;
    };

    const updatingClassContent = ref(false);
    const updateClassContent = async () => {
      updatingClassContent.value = true;

      try {
        await Firebase.setDoc(
          Firebase.collections.GROUP_CLASSES(groupId) +
          "/" +
          classBeingEdited.value.id,
          { conteudo: classBeingEdited.value.conteudo }
        );
        toast.open({
          message: "Conteúdo atualizado com sucesso!",
          type: "success",
          duration: 2000,
          position: "bottom",
          dismissible: true,
        });
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível atualizar esta aula!",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        updatingClassContent.value = false;
      }
    };

    const showClassRollCallModal = ref(false);
    const updatingClassRollCall = ref(false);

    const setShowClassRollCallModal = (newValue) => {
      showClassRollCallModal.value = newValue;
    };

    const updateClassRollCall = async () => {
      updatingClassRollCall.value = true;

      try {
        await Firebase.setDoc(
          Firebase.collections.GROUP_CLASSES(groupId) +
          "/" +
          classBeingEdited.value.id,
          { presencas: classBeingEdited.value.presencas }
        );
        toast.open({
          message: "Presença atualizada com sucesso!",
          type: "success",
          duration: 2000,
          position: "bottom",
          dismissible: true,
        });
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível atualizar esta aula!",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        updatingClassRollCall.value = false;
      }
    };

    const showPrintModal = ref(false);
    const setShowPrintModal = (newValue) => {
      showPrintModal.value = newValue;
    };

    const seriesArray = [];
    const cyclesArray = [];

    const loadingConfig = ref(false);

    const getConfig = async () => {
      loadingConfig.value = true;
      const configSnapshot = await Firebase.getDoc("config/prod");
      const config = configSnapshot.data();

      config.series.forEach((serie) =>
        seriesArray.push({
          value: serie,
          label: serie.nome,
        })
      );

      config.ciclos.forEach((cycle) =>
        cyclesArray.push({
          value: cycle,
          label: cycle.nome,
        })
      );

      loadingConfig.value = false;
    };

    const getSeriesName = (aluno) => {
      const seriesId =
        groupStudents.value.find((s) => s.id === aluno.id)?.serie ?? null;
      const s = seriesArray.find((serie) => serie.value.id === seriesId);
      return s?.value?.nome || "Sem série";
    };

    const getCycleName = (aluno) => {
      const cycleId =
        groupStudents.value.find((s) => s.id === aluno.id)?.ciclo ?? null;
      const c = cyclesArray.find((cycle) => cycle.value.id === cycleId);
      return c?.value?.nome || "Sem ciclo";
    };

    const printingRollCall = ref(false);

    const generatePrintableRollCall = () => {
      if (!month.value) {
        toast.open({
          message: "Não foi possível gerar chamada. Selecione um mês.",
          type: "error",
          duration: 3000,
          position: "bottom",
          dismissible: true,
        });
      }

      const filteredGroupClasses = groupClasses.value.filter(
        (g) => g.data.month === month.value.month
      );

      const previousGroupClasses = groupClasses.value.filter(
        (g) => g.data.month < month.value.month
      );

      const absencesMap = {};

      filteredGroupClasses.forEach((g) => {
        g.presencas.forEach((p) => {
          const _id = p.aluno.id;
          if (!absencesMap[_id]) {
            absencesMap[_id] = { mes: 0, acumulado: 0 };
          }

          if (!p?.presente) {
            absencesMap[_id].mes += 1;
          }
        });
      });

      previousGroupClasses.forEach((g) => {
        g.presencas.forEach((p) => {
          const _id = p.aluno.id;
          if (!absencesMap[_id]) {
            absencesMap[_id] = { mes: 0, acumulado: 0 };
          }

          if (!p?.presente) {
            absencesMap[_id].acumulado += 1;
          }
        });
      });

      const header = [
        { text: "#", style: "tableHeader" },
        { text: "Aluno", style: ["alignLeft", "tableHeader"] },
      ];
      const body = [];
      const contentBody = [["Data", "Conteúdo"]];

      let currentDate = DateTime.fromObject({
        day: 1,
        month: month.value.month,
        year: group.value.ano,
      });

      const workDays = [];

      do {
        if (currentDate.weekday >= 1 && currentDate.weekday < 6) {
          header.push({
            text: currentDate.toFormat("dd"),
            style: "tableHeader",
          });
          workDays.push(currentDate);
        }
        currentDate = currentDate.plus({ days: 1 });
      } while (currentDate.month === month.value.month);

      header.push({ text: "M", style: "tableHeader" });
      header.push({ text: "AC", style: "tableHeader" });

      groupStudents.value.forEach((s, i) => {
        const entry = [
          i,
          s.nome + ` (Série: ${getSeriesName(s)}, Ciclo: ${getCycleName(s)})`,
        ];

        workDays.forEach((workDay) => {
          const g = filteredGroupClasses.find((d) => {
            const { day, month, year } = d.data;
            return (
              day === workDay.day &&
              month === workDay.month &&
              year === workDay.year
            );
          });
          let presenca = "-";
          if (g) {
            const p = g.presencas.find((p) => p.aluno.id === s.id);
            presenca = p?.presente === null ? "-" : p?.presente ? "P" : "F";
          }
          entry.push(presenca);
        });

        entry.push(absencesMap[s.id]?.mes || "-");
        entry.push(absencesMap[s.id]?.acumulado || "-");

        body.push(entry);
      });

      workDays.forEach((workDay) => {
        const g = filteredGroupClasses.find((d) => {
          const { day, month, year } = d.data;
          return (
            day === workDay.day &&
            month === workDay.month &&
            year === workDay.year
          );
        });
        const content = [workDay.setLocale("pt-BR").toFormat("dd LLL"), ""];
        if (g) {
          content[1] = g.conteudo;
        }
        contentBody.push(content);
      });

      const docDefinition = {
        pageOrientation: "landscape",
        pageMargins: 10,
        content: [
          {
            columns: [
              {
                svg: logo,
                width: 200,
                height: 100,
              },
              [
                {
                  text: `Turma: ${group.value.turma}`,
                  style: "header",
                  fontSize: 20,
                  margin: [0, 20, 0, 0],
                },
                {
                  text: `Período: ${month.value.monthLong} de ${group.value.ano}`,
                  style: "header",
                  fontSize: 15,
                },
                {
                  text: `Professor(a): ${teacher.value.nome}`,
                  style: "subheader",
                  fontSize: 15,
                },
              ],
            ],
          },
          {
            margin: [0, 15, 0, 0],
            pageBreak: "after",
            table: {
              headerRows: 1,
              body: [header, ...body],
            },
          },
          // second page
          {
            text: `Conteúdo`,
            style: "header",
            fontSize: 20,
          },
          {
            text: `Turma: ${group.value.turma}`,
            style: "header",
            fontSize: 15,
            margin: [0, 0, 0, 15],
          },
          {
            table: {
              headerRows: 1,
              widths: [40, "*"],
              body: contentBody,
            },
          },
        ],
        styles: {
          defaultStyle: {
            alignment: "center",
          },
          tableHeader: {
            bold: true,
          },
          alignLeft: {
            alignment: "left",
          },
        },
      };

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(docDefinition).download();
    };

    return {
      group,
      loading,
      groupClasses,
      groupStudents,
      getGroupAndStudentsAndClasses,
      newClassDate,
      creatingNewClass,
      createNewClass,
      showBackdrop,
      setShowBackdrop,
      showNewClassModal,
      setShowNewClassModal,
      showClassContentModal,
      setShowClassContentModal,
      updatingClassContent,
      updateClassContent,
      classBeingEdited,
      setClassBeingEdited,
      showClassRollCallModal,
      updatingClassRollCall,
      setShowClassRollCallModal,
      updateClassRollCall,
      month,
      monthsArray,
      showPrintModal,
      setShowPrintModal,
      printingRollCall,
      generatePrintableRollCall,
      getConfig,
      deleteClass,
    };
  },
  methods: {
    navigate(name) {
      this.$router.push({ name });
    },
    getStudentName(aluno) {
      return (
        this.groupStudents.find((s) => s.id === aluno.id)?.nome ||
        "Aluno não identificado"
      );
    },
    onShowNewClassModal() {
      this.setShowNewClassModal(true);
      this.setShowBackdrop(true);
    },
    onCloseNewClassModal() {
      this.setShowNewClassModal(false);
      this.setShowBackdrop(false);
    },
    onShowClassContentModal(groupClass) {
      this.setClassBeingEdited(groupClass);
      this.setShowClassContentModal(true);
      this.setShowBackdrop(true);
    },
    onCloseClassContentModal() {
      this.setShowClassContentModal(false);
      this.setShowBackdrop(false);
    },
    onShowClassRollCallModal(groupClass) {
      this.setClassBeingEdited(groupClass);
      this.setShowClassRollCallModal(true);
      this.setShowBackdrop(true);
    },
    onCloseClassRollCallModal() {
      this.setShowClassRollCallModal(false);
      this.setShowBackdrop(false);
    },
    onShowPrintModal() {
      this.setShowPrintModal(true);
      this.setShowBackdrop(true);
    },
    onClosePrintModal() {
      this.setShowPrintModal(false);
      this.setShowBackdrop(false);
    },
    formatDate(data) {
      return data.toFormat("dd LLL yyyy", {
        locale: "pt-BR",
      });
    },
    onDeleteGroupClass(groupClass) {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Você realmente quer excluir a aula do dia '${this.formatDate(
          groupClass.data
        )}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não, cancelar",
        confirmButtonText: "Sim, excluir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteClass(groupClass.id);
        }
      });
    },
  },
  mounted() {
    this.getConfig();
    this.getGroupAndStudentsAndClasses();
  },
};
</script>

<style scoped>
.loading:after {
  display: inline-block;
  animation: dotty steps(1) 1s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }

  100% {
    content: "";
  }
}

.modal {
  position: fixed !important;
  height: auto !important;
  top: 100px !important;
  left: 0 !important;
  right: 0 !important;
}
</style>
